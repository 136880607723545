.formula-editor-wrapper .marker.hover-contents {
    display: none;
}

/* https://stackoverflow.com/questions/69303942/dont-let-flex-to-wrap-label-text-of-toggle */
label,
input,
button {
    width: 130px;
}

.handsontable .htDimmed {
    /* color: rgb(51,51,51) */
    color:black
}

.monaco .hover-content table, th, td {
    padding: 0px 5px 0px 5px;
    border: 0.7px solid rgba(51, 51, 51, 0.3);
    color: rgba(51, 51, 51) /* don't use pure black, because it is temporary data */
    /* color: rgba(97,97,97,255) */ /* same as single value in diagnostic window */
}