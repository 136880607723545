*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  font-family: sans-serif;
  box-sizing: border-box;
  overscroll-behavior-y: none;
}
